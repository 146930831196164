@font-face {
  font-family: "hum_me_tight";
  src: url("./fonts/Mf_HugMeTight.ttf") format("truetype");
}
@font-face {
  font-family: "tahoma";
  src: url("./fonts/Tahoma-Regular-font.ttf") format("truetype");
}
@font-face {
  font-family: "Katy Handwriting";
  src: url("./fonts/katyfont.ttf") format("truetype");
}
@font-face {
  font-family: "TT FORS";
  src: url("./fonts/TT Fors Trial Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Kids";
  src: url("./fonts/Kids-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "KG Miss Kindergarten";
  src: url("./fonts/KGMissKindergarten.ttf") format("truetype");
}
@font-face {
  font-family: "Simplicity";
  src: url("./fonts/simplicity.ttf") format("truetype");
}
@font-face {
  font-family: "Kiddish";
  src: url("./fonts/Kiddish.ttf") format("truetype");
}
@font-face {
  font-family: "Victorya Handwriting";
  src: url("./fonts/Victorya Handwriting.otf") format("truetype");
}
@font-face {
  font-family: "Handwriting 3";
  src: url("./fonts/Handwriting.ttf") format("truetype");
}
@font-face {
  font-family: "Cedarville Pink";
  src: url("./fonts/Cedarville PnkFun 1 Cursive.ttf") format("truetype");
}
@font-face {
  font-family: "CuteHandwriting";
  src: url("./fonts/CuteHandWriting.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Handwriting Mutlu";
  src: url("./fonts/Handwriting.ttf") format("truetype");
}
@font-face {
  font-family: "A little sunshine";
  src: url("./fonts/A little sunshine.ttf") format("truetype");
}
@font-face {
  font-family: "Moonpig";
  src: url("./fonts/moonpig-light-f5e923eb8dc7cba318c7740ebf4dc145.woff2")
      format("woff2"),
    url("./fonts/moonpig-light-0ab21b44f2b4c7c6c142b1b178ca2bd3.woff")
      format("woff");
}
