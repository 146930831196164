.thank-you-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
    padding: 50px 20px;
    text-align: center;
    background-color: #ffffff;
  }
  
  .thank-you-page h1 {
    font-weight: bold;
    color: #2e645f;
    margin-bottom: 20px;
  }
  
  .thank-you-page p {
    font-size: 34px;
  }
  